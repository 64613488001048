import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import type {
	OverlayDependencyItem,
	DependencyItemSource,
} from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/types';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { swimlaneHeaderHeight } from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	isIssueEntryIssue,
	type IssueId,
	type IssueEntry,
	type IssueLink,
} from '@atlassian/jira-software-board-common';
import type { RowState } from '@atlassian/jira-software-fast-virtual/src/common/types';
import type { RowDescriptor } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual';
import { createStore, createHook, createSelector, type Action } from '@atlassian/react-sweet-state';

type ColumnId = number;

type ColumnPosition = { x: number; width: number; height: number };

type DependencyLinesContainerPosition = { x: number };

type DependencyEdge = 'left' | 'right';

type IssuePosition = RowState & { relativeTop: number };

export type IssueData = {
	id: IssueId;
	columnId: ColumnId;
	teamId?: string | null; // Represents the swimlane that the issue is in
	issueLinks?: IssueLink[] | null;
};

export type State = {
	dependencyLinesContainerPosition: DependencyLinesContainerPosition;
	issuesWithIssueLinkPositionMap: Map<string, IssuePosition>;
	swimlanePositionMap: Map<string, { isVisible: boolean; swimlanePosition: RowState }>;
	columnPositionMap: Map<ColumnId, ColumnPosition>;
};

type Actions = typeof actions;

const initialState: State = {
	dependencyLinesContainerPosition: { x: 0 },
	issuesWithIssueLinkPositionMap: new Map(),
	swimlanePositionMap: new Map(),
	columnPositionMap: new Map(),
};

const actions = {
	// We save the board position because we want to draw lines relative to the board, not the viewport
	// Add the board's left and top positions from line calculations to offset the board
	updateDependencyLinesContainerPosition:
		(dependencyLinesContainerPosition: DependencyLinesContainerPosition): Action<State> =>
		({ setState }) => {
			setState({ dependencyLinesContainerPosition });
		},

	updateIssuesWithIssueLinkPositions:
		(
			issueEntries: IssueEntry[],
			issuesWithIssueLinksIds: Array<IssueId>,
			issuePositions?: RowState[],
			isUnscheduledWorkColumnPanel?: boolean,
			swimlaneId?: string | null,
		): Action<State> =>
		({ setState, getState }) => {
			if (
				!isUnscheduledWorkColumnPanel &&
				issuePositions &&
				fg('dependency_visualisation_program_board_fe_and_be')
			) {
				const { swimlanePositionMap } = getState();

				if (swimlanePositionMap === undefined || isNil(swimlaneId)) {
					return;
				}

				const swimlanePosition = swimlanePositionMap.get(swimlaneId);

				if (swimlanePosition === undefined) {
					return;
				}

				const issueIdsWithLinks = issueEntries.reduce<{ issueId: string; index: number }[]>(
					(acc, issueEntry, index) => {
						if (
							isIssueEntryIssue(issueEntry) &&
							includes(issuesWithIssueLinksIds, issueEntry.issueId)
						) {
							acc.push({ issueId: String(issueEntry.issueId), index });
						}
						return acc;
					},
					[],
				);

				const { issuesWithIssueLinkPositionMap } = getState();

				const newIssuesWithIssueLinkPositionMap = new Map(issuesWithIssueLinkPositionMap);

				issueIdsWithLinks.forEach(({ issueId, index }) => {
					const issuePosition = issuePositions[index];
					if (issuePosition) {
						newIssuesWithIssueLinkPositionMap.set(issueId, {
							...issuePosition,
							relativeTop: issuePosition.top - swimlanePosition.swimlanePosition.top,
						});
					}
				});

				setState({
					issuesWithIssueLinkPositionMap: newIssuesWithIssueLinkPositionMap,
				});
			}
		},

	updateSwimlanePositions:
		(
			swimlanePositions: RowState[],
			visibleSwimlanePositions: RowDescriptor[],
			swimlaneIds: string[],
		): Action<State> =>
		({ setState }) => {
			const newSwimlanePositionMap = new Map();

			swimlaneIds.forEach((swimlaneId, index) => {
				const swimlanePosition = swimlanePositions[index];
				const isVisible = visibleSwimlanePositions.some(
					(visibleSwimlane) => visibleSwimlane.index === index,
				);
				if (swimlaneId && swimlanePosition) {
					newSwimlanePositionMap.set(swimlaneId, {
						isVisible, // aka is on screen
						swimlanePosition,
					});
				}
			});

			setState({ swimlanePositionMap: newSwimlanePositionMap });
		},

	updateColumnPositionMap:
		(columnPosition: ColumnPosition, columnId: number): Action<State> =>
		({ setState, getState }) => {
			const { columnPositionMap } = getState();

			const newColumnPositionMap = new Map(columnPositionMap);

			newColumnPositionMap.set(columnId, columnPosition);

			setState({ columnPositionMap: newColumnPositionMap });
		},
};

const Store = createStore<State, Actions>({
	name: 'dependencyLineStore',
	initialState,
	actions,
});

export const useDependencyLinks = createHook(Store);

const getYPosition = (
	issue: IssueData,
	issuesWithIssueLinkPositionMap: Map<IssueId, IssuePosition>,
	swimlanePositionMap: Map<string, { isVisible: boolean; swimlanePosition: RowState }>,
	columnPositionMap: Map<number, ColumnPosition>,
): number => {
	const swimlaneId = issue.teamId ?? SWIMLANE_TEAMLESS;

	const swimlanePosition = swimlanePositionMap.get(swimlaneId);

	// When the page loads, it is possible that we try to get this data before it's ready - returning a default value to begin with
	if (issuesWithIssueLinkPositionMap === undefined || swimlanePosition === undefined) {
		return 0;
	}

	const issuePosition = issuesWithIssueLinkPositionMap.get(String(issue.id));

	// Due to a quirk with virtualisation sometimes we do get the position of a card before it's been scrolled onto screen during the initial renders
	// We have issue y positions that aren't strictly correct saved into state, and should only depend on this data once the swimlane that it is in
	// is actually scrolled onto screen. We aren't able to get the vertical position of those issues once everything has rendered onto the page
	// because the parent swimlane component doesn't try to virtualise its child issues list if it is itself virtualised (not rendered).
	if (issuePosition) {
		const columnHeight = columnPositionMap.get(issue.columnId)?.height ?? 0;
		const top =
			swimlanePosition.swimlanePosition.top +
			issuePosition.relativeTop +
			columnHeight +
			swimlaneHeaderHeight;
		return top + issuePosition.height / 2;
	}

	// if the swimlane the issue belongs to is offscreen, we just draw the line to halfway down the swimlane.
	if (swimlanePosition.isVisible === false) {
		return swimlanePosition.swimlanePosition.top + swimlanePosition.swimlanePosition.height / 2;
	}

	// If the swimlane is visible, we should theoretically have the issue position and not gotten here.
	logger.safeErrorWithoutCustomerData(
		'plan-increment-common.useDependencyLinks.getYPosition',
		'Failed to find the y position of an issue when calculating dependency line positions.',
	);

	// Return a default value in case we can't find the y position of the card we're trying to draw to.
	return 0;
};

const getXPosition = (
	issue: IssueData,
	dependencyLinesContainerPosition: DependencyLinesContainerPosition,
	columnPositionMap: Map<number, ColumnPosition>,
	edge: DependencyEdge,
): number => {
	const columnPosition = columnPositionMap.get(issue.columnId);

	if (columnPosition) {
		return (
			columnPosition.x +
			(edge === 'left' ? 0 : columnPosition.width) -
			dependencyLinesContainerPosition.x
		);
	}

	// Issues must belong to a column - we theoretically shouldn't have gotten here.
	logger.safeErrorWithoutCustomerData(
		'plan-increment-common.useDependencyLinks.getXPosition',
		'Failed to find the x position of an issue when calculating dependency line positions.',
	);

	return 0;
};

const getIssuePosition = (
	state: State,
	issue: IssueData,
	edge: DependencyEdge = 'right',
): DependencyItemSource => {
	const {
		dependencyLinesContainerPosition,
		issuesWithIssueLinkPositionMap,
		swimlanePositionMap,
		columnPositionMap,
	} = state;

	const yPosition = getYPosition(
		issue,
		issuesWithIssueLinkPositionMap,
		swimlanePositionMap,
		columnPositionMap,
	);

	const xPosition = getXPosition(issue, dependencyLinesContainerPosition, columnPositionMap, edge);

	return {
		id: String(issue.id),
		y: yPosition,
		x: xPosition,
		color: 'DARK_GREY',
	};
};

// Arbitrary number - this affects the curvature of the drawn dependency line only.
const ITEM_HEIGHT = 40;

const getDependencyLine = ({
	state,
	from,
	to,
}: {
	state: State;
	from: IssueData;
	to: IssueData;
}): OverlayDependencyItem => ({
	itemHeight: ITEM_HEIGHT,
	isOverlapping: false,
	from: getIssuePosition(state, from, 'right'),
	to: getIssuePosition(state, to, 'left'),
});

export const getDependencyLinesFromStateAndIssueData = (
	state: State,
	issuesWithLinksById: Record<string, IssueData>,
): OverlayDependencyItem[] => {
	if (!issuesWithLinksById || Object.values(issuesWithLinksById).length === 0) {
		return [];
	}

	const uniqueIssueLinkIds = new Set();

	const overlayDependencies = Object.values(issuesWithLinksById).reduce<OverlayDependencyItem[]>(
		(acc, issue) => {
			if (issue.issueLinks) {
				issue.issueLinks.forEach((issueLink) => {
					// Every issue link appears twice, so we don't want to handle ones we have already handled.
					if (!uniqueIssueLinkIds.has(issueLink.id)) {
						uniqueIssueLinkIds.add(issueLink.id);
						// We have already filtered out issues that are in the unscheduled column
						// If the other end of the issue link is in the unscheduled column we also don't want to handle it.
						if (
							issuesWithLinksById[issueLink.sourceId] &&
							issuesWithLinksById[issueLink.destinationId]
						) {
							acc.push(
								getDependencyLine({
									state,
									from: issuesWithLinksById[issueLink.sourceId],
									to: issuesWithLinksById[issueLink.destinationId],
								}),
							);
						}
					}
				});
			}
			return acc;
		},
		[],
	);

	return overlayDependencies;
};

export type DependencyLinesProps = { issuesWithLinksById: Record<string, IssueData> };

const getDependencyLines = createSelector<
	State,
	DependencyLinesProps,
	OverlayDependencyItem[],
	State,
	DependencyLinesProps
>(
	(state: State) => state,
	(_: State, props: DependencyLinesProps) => props,
	(state: State, { issuesWithLinksById }: DependencyLinesProps) => {
		return getDependencyLinesFromStateAndIssueData(state, issuesWithLinksById);
	},
);

export const useIPDependencyLines = createHook(Store, {
	selector: getDependencyLines,
});
