import React from 'react';
import { Text, Box, xcss } from '@atlaskit/primitives';

interface Props {
	children: React.ReactNode;
	isDisabled?: boolean;
	helpTextId: string;
	helpText: string;
}

export const DisabledButtonHelper: React.FC<Props> = ({
	children,
	isDisabled,
	helpTextId,
	helpText,
}) => {
	return (
		<Box paddingInlineStart="space.050" xcss={buttonWrapperStyles}>
			{isDisabled && (
				<Box xcss={helperTextWrapperStyles}>
					<Text as="p" size="small" color="color.text.subtle" id={helpTextId}>
						{helpText}
					</Text>
				</Box>
			)}
			{children}
		</Box>
	);
};

const buttonWrapperStyles = xcss({
	display: 'inline-flex',
	gap: 'space.050',
});

const helperTextWrapperStyles = xcss({
	textAlign: 'right',
	width: '120px',
	// @ts-expect-error - TS2353 - Object literal may only specify known properties, and 'textWrap' does not exist in type 'SafeCSSObject'.
	textWrap: 'wrap', // required for v3 nav layout cascade
});
